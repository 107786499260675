import { Image } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'

const ApplicantNotFoundPage = () => (
  <div className="flex h-screen flex-col">
    <main className="flex-grow overflow-auto">
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <Image
            radius="md"
            h={400}
            style={{ margin: '0 auto' }}
            w="auto"
            fit="contain"
            src="/images/404-truck-no-background.png"
            alt="truck404"
          />
          <p className="mt-4 text-gray-600">
            Sorry we couldn't find the applicant you're looking for. Please make
            sure to confirm the account number and try again.
          </p>
          <a href="/">
            <Button text="Go Home" className="mt-6" />
          </a>
        </div>
      </div>
    </main>
  </div>
)

export default ApplicantNotFoundPage
